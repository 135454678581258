import React from 'react'
import { Link } from 'gatsby'

const Breadcrumb = ({ title , rootUrl , parentUrl, currentUrl, styles}) => {
  return (
    <div className={`breadcrumb-area ptb--165 ${styles}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner text-center">
              <h1 className="title theme-gradient h1" dangerouslySetInnerHTML={{__html: title}}></h1>
              <div className="outer">
                <div className="box">
                  <ul className="page-list text-center">
                    <li className="rn-breadcrumb-item"><Link to={`${rootUrl}`} dangerouslySetInnerHTML={{__html: parentUrl}}></Link></li>
                    <li className="rn-breadcrumb-item active" dangerouslySetInnerHTML={{__html: currentUrl}}></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
